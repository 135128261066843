.product-slider {
  position: relative;
  margin-left: -8px;
  margin-right: -8px;

  @include media-breakpoint-up(lg) {
    margin-left: -12px;
    margin-right: -12px;
  }

  @include media-breakpoint-up(xxl) {
    margin-left: -16px;
    margin-right: -16px;
  }

  .HitCard,
  .video-hit-card,
  .challenger-hit-card,
  .fake-hit-card {
    margin-bottom: 0;
  }

  .HitCardContainer,
  .fake-card-container {
    padding: 0;
    margin-top: 5px;

    @include theme(light) {
      margin-bottom: $card-carousel-margin;
    }
  }

  .slick-slide {
    padding: 0 8px;

    @include media-breakpoint-up(lg) {
      padding: 0 12px;
    }

    @include media-breakpoint-up(xxl) {
      padding: 0 16px;
    }
  }
}

// ProductCardCarousel Site Slider Settings
// These changes help the labels show when the slider has overflow hidden

.ProductCardCarousel {
  .SiteSlider__slidesContainer {
    @include media-breakpoint-up(sm) {
      overflow-x: visible !important;
    }

    @include media-breakpoint-up(lg) {
      overflow-x: hidden !important;
      margin-left: var(--negative-gutter);
      margin-right: var(--negative-gutter);
    }

    .SiteSlider__slides {
      @include media-breakpoint-up(sm) {
        padding-left: 5px;
        margin-left: -5px;
        padding-right: 5px;
        margin-right: -5px;
      }

      @include media-breakpoint-up(lg) {
        gap: 0;
        padding: 0;
        margin: 0;
      }

      .SiteSlider__slide {
        @include media-breakpoint-up(lg) {
          margin: 0 var(--gutter-padding);
        }
      }
    }
  }

  .HitCard,
  .video-hit-card,
  .challenger-hit-card,
  .fake-hit-card {
    margin-bottom: 0;
  }

  .HitCardContainer,
  .fake-card-container {
    padding: 0;
    margin-top: 5px;

    @include theme(light) {
      margin-bottom: $card-carousel-margin;
    }
  }

  .FakeHitCard {
    width: 100%;
    border-radius: $border-radius;

    @include theme(light) {
      box-shadow: var(--card-box-shadow);
    }

    .responsive-image {
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .FakeHitCard__strip {
      background-color: var(--card-background-color);
      border-bottom-left-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
      padding: 0.35rem 0.5rem;
      height: 48px;
      margin-top: -1px;
    }
  }
}
