.HitCard {
  position: relative;
  margin-bottom: 1rem;
  border-radius: $border-radius;

  @include media-breakpoint-up(lg) {
    margin-bottom: 1.5rem;
  }

  @include media-breakpoint-up(xxl) {
    margin-bottom: 2rem;
  }

  .responsive-image {
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;

    img {
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;
      overflow: hidden;
    }
  }

  .hit-card-overlay {
    position: absolute;
    background-color: transparent;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    top: 0;
    right: 0;
    bottom: $hit-card-stripe-height;
    left: 0;
    text-align: center;
    transition: all ease 0.1s;
    padding: 0;
    z-index: 5;
    display: flex;

    .hide-overlayContent {
      opacity: 0;
    }

    .overlayContent {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;

      .overlay-content-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        opacity: 0;
      }

      .hit-card-buttons-container {
        display: flex;
        justify-content: center;
        margin-bottom: 0.25rem;

        .btn {
          display: flex;
          align-items: center;
          justify-content: center;
          min-height: 36px;
          border-radius: $border-radius-sm;

          svg {
            margin-right: 0.125rem;
          }
        }

        .dropdown-item {
          font-size: 0.875rem;
          font-weight: 400;
        }
      }

      .btn {
        min-width: 4.75rem;
        padding: 0.475rem 0.375rem 0.5rem;
        margin: 1px 4px;
        cursor: pointer;
        font-size: 0.6875rem;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 0.25px;
        text-align: center;
        z-index: -10;

        @include media-breakpoint-up(xl) {
          font-size: 0.75rem;
          margin: 1px 5px;
        }
      }

      // OFF WISHLIST BUTTON
      .wishlist-button {
        position: absolute;
        right: 8px;
        top: 8px;
        color: $grey-8;
        display: flex;

        button {
          margin: 0;
          padding: 0;
          width: auto;
        }

        button.wishlist-add {
          color: $primary-core !important;
          min-width: auto;
        }

        button.wishlist-remove {
          color: $grey-8 !important;
          min-width: auto;

          &:hover {
            color: $primary-core !important;
          }
        }
      }

      // ON WISHLIST BUTTON
      .wishlist-icon {
        position: absolute;
        top: 0;
        right: 0;
        margin-top: 4px;
        margin-right: 8px;
        z-index: 9;
        box-shadow: 0 2px 8px rgba($black, 0.12);
        border-radius: 15px;

        .card-action {
          height: 25px;
          background-color: $white;
          border-radius: 15px; // Half of the height to make the ends round
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 2px 0;
          padding: 0 6px;
          color: $white;
          border: none;
          box-shadow: 0 2px 8px rgba($black, 0.12);

          .action-icon {
            color: $primary-core;
            display: flex;
            margin-right: 0;
            position: relative;

            .fa-heart {
              font-size: 13px;
            }
          }
        }
      }

      .card-actions-container {
        position: absolute;
        top: 0;
        right: 0;
        margin-top: 8.5px;
        margin-right: 20px;
        flex-direction: column;
        align-items: flex-end;
        border-radius: 15px;

        .card-action {
          height: 17px;
          background-color: $white;
          border-radius: 3px; // Half of the height to make the ends round
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 2px 0;
          padding: 0;
          color: $white;
          border: none;
          max-width: 0; // This is for the slide out transition
          transition: max-width 0.4s ease;

          .action-text {
            margin-right: 0;
            font-size: 11px;
            font-weight: 700;
            white-space: nowrap;
            overflow: hidden;
            text-transform: uppercase;
            color: $text-black;
            display: none;
            padding-left: 0.3rem;
          }
        }
      }

      .quicklook-container {
        .btn-link {
          color: $text-white;
          padding: 0;
          font-weight: 300;
          text-decoration: underline;
          text-transform: none;

          &:hover {
            color: var(--brand-core, $primary-core);
          }
        }
      }

      .price-asterix {
        color: $grey-4;
        font-size: 12px;
      }
    }
  }

  .wishlist-hit-card-buttons-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 0.5rem 0.5rem 0.5rem;

    @include media-breakpoint-up(lg) {
      display: none;
    }

    button {
      z-index: 9;
      margin-left: 0.5rem !important;
      font-weight: 400;
    }

    a {
      flex-grow: 1;
      text-transform: uppercase;
    }

    .btn-primary {
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
    }

    .btn-group {
      flex-grow: 1;

      .dropdown-menu {
        width: 100%;
      }

      .dropdown-item {
        font-weight: 400;
        font-size: 0.875rem;
        display: flex;
        flex-wrap: wrap;
      }
    }

    .tiered-gift-button {
      display: block;
      z-index: 9;
    }
  }

  .hit-card-game-name {
    font-size: 0.875rem;
    line-height: 17px;
    text-align: center;
    overflow: hidden;
    margin-bottom: 0.5rem;
    font-weight: 400 !important;

    a {
      color: $white;
    }
  }

  .hitCardStripe {
    position: relative;
    font-size: 0.813rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    height: $hit-card-stripe-height;
    display: flex;
    flex-direction: column;
    transition: all ease 0.1s;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;

    .card-icons-price-container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-grow: 1;
      flex-direction: row;

      @include media-breakpoint-up(md) {
        justify-content: space-between;
        flex-direction: row-reverse;
      }

      .drm-dlc-container {
        display: flex;
        align-items: center;
      }

      .dlc-badge {
        display: none;

        @include media-breakpoint-up(xl) {
          display: block;
          margin-left: 0.75rem;
        }
      }
    }

    .game-price {
      font-size: 11px;

      p {
        margin: 0;
      }
    }

    .was-price {
      position: relative;
      margin-right: 0.5rem;
      text-decoration: line-through;
    }

    .card-os-icons {
      display: none;
      line-height: 15px;
    }

    .book-bundle {
      display: none;

      @include media-breakpoint-up(md) {
        display: block;
        text-transform: uppercase;
        font-weight: 400;
        font-size: 11px;
      }

      @include media-breakpoint-up(xl) {
        font-size: 12px;
      }

      svg {
        @include media-breakpoint-up(md) {
          margin-right: 3px;
        }

        @include media-breakpoint-up(xl) {
          margin-right: 4px;
        }

        @include media-breakpoint-up(xxl) {
          margin-right: 6px;
        }

        &.fa-headphones {
          margin-top: -1px;
        }
      }
    }
  }

  .hitCardStripe__content {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: space-between;
    height: 100%;

    .hitCardStripe__content__purchase {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      @include media-breakpoint-up(xl) {
        gap: 0.75rem;
      }

      .AddToCartBtn {
        padding: 0;
        width: 32px;
        height: 32px;
        display: flex !important;
        align-items: center;
        justify-content: center;

        .cart-btn-icon {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;

          svg {
            margin: 0 !important;
          }
        }
      }
    }

    .hitCardStripe__content__icons {
      display: flex;
      align-items: center;
      gap: 0.75rem;

      .hitCardStripe__content__icons__os {
        display: none;
        color: var(--card-icons-color);
      }

      .hitCardStripe__content__icons__nonGame {
        display: none;

        @include media-breakpoint-up(md) {
          display: flex;
          align-items: center;
          text-transform: uppercase;
          font-weight: 400;
          font-size: 11px;
          color: var(--card-icons-color);
        }

        svg {
          font-size: 13px;

          @include media-breakpoint-up(md) {
            margin-right: 4px;
          }

          &.fa-headphones {
            margin-top: -1px;
          }
        }
      }
    }
  }

  .timer-progress-container {
    padding: 0.5rem;
    display: flex;
    align-items: center;
    height: 3.125rem;
    flex-direction: column;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;

    @include theme(light) {
      border-top: 1px solid $grey-2;
      background-color: var(--card-background-color);
      color: var(--site-font-color);
    }

    @include media-breakpoint-up(lg) {
      height: 2.688rem;
      flex-direction: row;
    }

    .flash-timer-container {
      display: flex;
      align-items: center;
      width: 100%;
      font-size: 11px;
      margin-bottom: 0.25rem;

      @include theme(light) {
        color: var(--site-font-color);
      }

      @include media-breakpoint-up(md) {
        font-size: 12px;
      }

      @include media-breakpoint-up(lg) {
        width: 55%;
        margin-bottom: 0;
        padding-right: 0.5rem;
      }

      .timer-text {
        text-transform: uppercase;
        margin-right: 5px;
        font-size: 11px;

        @include media-breakpoint-up(md) {
          font-size: 12px;
        }
      }

      .sd-timer {
        font-weight: 700;

        .colon {
          padding: 0 2px;
        }
      }
    }

    .progress-container {
      width: 100%;

      @include media-breakpoint-up(lg) {
        width: 45%;
      }

      .progress {
        height: 10px;

        @include theme(light) {
          background-color: $grey-3;
        }

        .progress-green {
          background-color: $progress-green;
        }

        .progress-yellow {
          background-color: $progress-yellow;
        }

        .progress-red {
          background-color: $progress-red;
        }
      }
    }
  }

  .select-btn {
    @include button-variant($primary-core, $primary-core);

    color: $grey-14;
    font-weight: 700;
    width: 100% !important;
    margin: 0 !important;
    text-transform: uppercase;
    min-width: 100px !important;
    font-size: 12px;
    z-index: 5;

    @include media-breakpoint-up(lg) {
      font-weight: 400;
      font-size: 14px;
    }

    &:hover,
    &:focus {
      color: $grey-14 !important;
    }

    &.btn-selected {
      background-color: $grey-7;
      border-color: $grey-7;
      color: $text-black;

      &:hover,
      &:focus {
        background-color: $grey-8 !important;
        border-color: $grey-8 !important;
        color: $text-black !important;
      }
    }
  }

  // HOVER STYLING GENERAL
  // ------------------------

  @include hover-supported() {
    .hit-card-overlay {
      background-color: rgba($black, 0.95);

      .hide-overlayContent {
        opacity: 1;
      }

      .overlayContent {
        .overlay-content-container {
          opacity: 1;
        }

        .wishlist-button {
          z-index: 7;
        }

        .card-actions-container {
          z-index: 7;

          @include media-breakpoint-up(xl) {
            .card-action {
              display: flex;
              width: auto;
              max-width: 400px;

              .action-text {
                @include media-breakpoint-up(xl) {
                  display: block;
                  margin-right: 1rem;
                }
              }
            }
          }
        }

        .btn {
          z-index: 6;
        }
      }
    }

    .hitCardStripe {
      background-color: rgba($black, 1) !important;
      border-bottom-left-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
      color: $white;

      .card-saving {
        background-color: $grey-11 !important;
        color: $white;
      }

      .drm-dlc-container,
      .drm-container,
      .dlc-badge {
        display: none !important;
      }

      .card-os-icons {
        @include media-breakpoint-up(md) {
          display: flex;
          align-items: center;
          font-size: 12px;
        }

        .fa-android {
          margin-top: 1px;
        }
      }

      .hitCardStripe__content {
        .hitCardStripe__content__icons {
          .hitCardStripe__content__icons__os {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            color: $card-icons-color-light !important;
          }
        }
      }
    }

    .timer-progress-container {
      background-color: $black;
      color: $white;
      border-top: 1px solid $grey-11;
      border-bottom-left-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
      transition: all ease 0.1s;

      .flash-timer-container {
        color: $white;
      }
    }

    .big-card-sdesc {
      background-color: rgba($black, 1) !important;
    }
  }

  // HIT CARD THEMES
  // ------------------------

  // DARK CARD

  &--dark {
    background-color: $card-background-color-dark;

    .hitCardStripe {
      background-color: $card-background-color-dark;

      .card-saving,
      .price-replacement,
      .card-os-icons,
      .book-bundle,
      .hitCardStripe__content__icons__nonGame,
      .hitCardStripe__content__purchase__price__text {
        color: $text-white;
      }

      .game-price {
        color: $grey-4;
      }

      .was-price {
        color: $grey-5;
      }
    }

    .timer-progress-container {
      border-top: 1px solid $grey-11;
      background-color: $grey-11;
      color: $white;
    }
  }

  // LIGHT CARD

  &--light {
    background-color: $card-background-color-light;
    box-shadow: var(--card-box-shadow);

    .hitCardStripe {
      .card-saving {
        background-color: $card-percentage-background-color-light;
        color: $font-color-dark;
      }
    }

    .timer-progress-container {
      border-top: 1px solid $grey-2;
      background-color: $card-background-color-light;
      color: $font-color-dark;

      .flash-timer-container {
        color: $font-color-dark;
      }

      .progress-container {
        .progress {
          background-color: $grey-3;
        }
      }
    }

    .hitCardStripe__content__icons__nonGame {
      color: $card-icons-color-dark !important;
    }
  }

  &--popup {
    @include hover-supported() {
      .hitCardStripe {
        background-color: var(--card-background-color-dark) !important;

        .drm-dlc-container,
        .drm-container,
        .dlc-badge {
          display: flex !important;
        }

        .card-saving {
          background-color: var(--percentage-background-color) !important;
          color: var(--site-font-color) !important;
        }
      }
    }
  }

  // HIT CARD PICK & MIX
  // ------------------------

  &.card-selected {
    background-color: rgba($black, 0.95);

    .in-cart-label {
      z-index: 6;
    }

    .hitCardStripe {
      border: 2px solid var(--brand-core, $primary-core) !important;
      border-top: 0 !important;

      @include media-breakpoint-down(md) {
        border-bottom: 0 !important;
      }

      @include media-breakpoint-up(lg) {
        border-bottom-left-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
      }
    }

    .hit-card-overlay {
      border: 2px solid var(--brand-core, $primary-core) !important;
      border-bottom: 0 !important;
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;
    }
  }

  // HIT CARD FLASH DEAL
  // ------------------------

  &.flash-hit-card {
    .hit-card-overlay {
      bottom: calc(48px + 43px); // Height of hitCradStripe + timer-progress-container
    }

    @include hover-supported() {
      .hitCardStripe {
        border-radius: 0;
      }
    }
  }
}

// BIG HIT CARD
// ------------------------

.big-hit-card {
  .hit-card-game-name {
    font-size: 20px;
    line-height: 26px;
    font-weight: 700;
    height: 58px;
    padding-top: 0.25rem;
  }

  .hitCardStripe {
    height: calc(#{$hit-card-stripe-height} + 69px);
  }

  .hit-card-overlay {
    bottom: calc(#{$hit-card-stripe-height} + 69px);

    .overlayContent {
      h6 {
        max-width: 300px;
        margin: 0 auto 1rem;
        font-size: 1.25rem;
      }
    }
  }

  .card-icons-price-container {
    height: 60px;
  }

  .big-card-sdesc {
    padding: 0;
    font-size: 14px;
    height: 100px;
  }

  @include hover-supported() {
    .big-card-sdesc,
    .hit-card-game-name {
      background-color: rgba($black, 0.15);
    }
  }
}

.in-cart-label {
  color: var(--brand-font-color, $text-black);
  background-color: var(--brand-core, $primary-core);
  position: absolute;
  top: 0;
  right: 0;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 700;
  padding: 3px 8px;
  border-top-right-radius: $border-radius;
  z-index: 2;
  display: flex;
  align-items: center;

  @include media-breakpoint-up(lg) {
    font-size: 11px;
  }

  .fa-check-circle,
  .fa-check {
    margin-right: 3px;
  }
}
